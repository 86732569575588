.App {
  display: flex;
  flex-direction: row;
}

.floating-div {
  animation: hover 2.6s infinite ease-in-out;
}
.floating-text {
  animation: hover-text 2.6s infinite ease-in-out;
}

@keyframes hover {
  0% {
    transform: translateY(0%) translateX(-66%);
  }
  50% {
    transform: translateY(-140%) translateX(-66%);
  }
  100% {
    transform: translateY(0%) translateX(-66%);
  }
}
@keyframes hover-text {
  0% {
    transform: translateY(0%) translateX(-50%);
  }
  50% {
    transform: translateY(-140%) translateX(-50%);
  }
  100% {
    transform: translateY(0%) translateX(-50%);
  }
}